import ReactPlayer from 'react-player/lazy';
import styles from './FeaturedVideo.module.scss';

const FeaturedVideo = ({
  previewMedia,
  featuredMedia,
  isMuted,
  isPlaying = false,
  isPlayingPreview = true,
  isPlayingMain = false,
  expanded = false,
  onEnded = () => {},
}: {
  previewMedia?: any;
  featuredMedia?: any;
  isMuted?: boolean;
  isPlaying?: boolean;
  isPlayingPreview?: boolean;
  isPlayingMain?: boolean;
  expanded?: boolean;
  onEnded?: () => void;
}) => {
  return (
    <div
      className={`${styles.featuredVideo__wrapper} ${
        expanded ? styles.expanded : ''
      }`}
    >
      <div className={`${styles.featuredVideo__preview}`}>
        <ReactPlayer
          url={previewMedia?.url}
          width='100%'
          height='100%'
          playing={isPlayingPreview}
          muted={true}
          loop={true}
          playsinline
          controls={false}
          onEnded={onEnded}
        />
      </div>

      <div className={`${styles.featuredVideo__main}`}>
        <ReactPlayer
          url={featuredMedia?.url}
          width='100%'
          height='100%'
          playing={isPlayingMain ? isPlaying : false}
          muted={isPlayingMain ? isMuted : true}
          loop={false}
          playsinline
          controls={!isMuted && isPlayingMain && isPlaying}
          onEnded={onEnded}
        />
      </div>
    </div>
  );
};

export default FeaturedVideo;
