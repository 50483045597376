import React, { useContext, useEffect, useState } from 'react';

import type { CalloutHero, ComponentCta } from '@/types';

import { convertMarkdown, useIsMobile, UIContext } from '@/ui/utils';
import {
  ComponentCtaButton,
  HeroImageClip,
  HeroVideoClip,
  MarketoForm,
  Modal,
} from '@/ui';

import styles from './ComponentCalloutHero.module.scss';

const ComponentCalloutHero = ({
  heroHeading,
  heroMedia,
  heroBody,
  ctas,
  heroColour = '#04aa9e',
  heroSpacing = false,
  isCarouselSlide = false,
  previewVideo = undefined,
  playWithoutSound,
  gatedMediaCheckbox = false,
  gatedMediaMarketoFormId = '',
}: CalloutHero) => {
  const isMuted = playWithoutSound;

  const { playVideoText, pauseVideoText } = useContext(UIContext);

  const [isPlaying, setIsPlaying] = useState(!gatedMediaCheckbox);
  const [isVideo, setIsVideo] = useState(false);

  const [gatedAccepted, setGatedAccepted] = useState(false);
  const [gatedModelOpen, setGatedModelOpen] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  const allowContent = !gatedMediaCheckbox || gatedAccepted;

  const isMobile = useIsMobile();
  const hasMedia = heroMedia?.url;

  const isImage = heroMedia?.contentType.startsWith('image');
  const hasCtas = !!ctas?.length;

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  const handleFormClose = () => {
    setGatedModelOpen(false);
  };

  const handleFormSuccess = () => {
    setGatedModelOpen(false);
    setGatedAccepted(true);
    setIsPlaying(true);
  };

  const renderVideoContent = () => {
    if (!isMounted) {
      return null;
    }

    if (gatedMediaCheckbox && !gatedAccepted) {
      if (!previewVideo) {
        return (
          <div
            className={`${styles.calloutHero__image} ${styles.gatedNoVideo}`}
          >
            Gated content has been set up incorrectly. <br />
            Please check that a Preview video has been uploaded.
          </div>
        );
      }
      return (
        <HeroVideoClip
          isPlaying={isPlaying}
          heroMedia={previewVideo}
          ovalBottom
          loop={false}
          isMuted={isMuted}
          priority={isCarouselSlide}
          onEnded={() => {
            setIsPlaying(false);
            gatedMediaMarketoFormId
              ? setGatedModelOpen(true)
              : alert(
                  'Gated content has been set up incorrectly.\nPlease check that a Marketo Form ID has been added.'
                );
          }}
        />
      );
    }

    if (allowContent) {
      return (
        <HeroVideoClip
          isPlaying={isPlaying}
          heroMedia={heroMedia}
          ovalBottom
          isMuted={!allowContent && isMuted}
          priority={isCarouselSlide}
        />
      );
    }

    return null;
  };

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    const video = !isImage;
    if (video) {
      setIsVideo(video);
    }
  }, [heroMedia, isImage]);

  return (
    <div
      id='calloutHero'
      // @ts-expect-error: --callout does not exist on style interface.
      style={{ '--callout': `${heroColour}` }}
      className={`
      ${styles.calloutHero} 
      ${!hasMedia && styles.withoutMedia} 
      ${heroSpacing && !isCarouselSlide && styles.withSpacing}
      ${isCarouselSlide && styles.isCarousel}
    `}
    >
      <div
        className={`
          ${styles.calloutHero__container} 
          ${hasMedia && styles.noPaddingRight}
          ${!hasMedia && isCarouselSlide && styles.noPadding}
        `}
      >
        <div className={styles.calloutHero__content}>
          <h2 className={styles.calloutHero__heading}>{heroHeading}</h2>
          {heroBody && (
            <div className={styles.calloutHero__body}>
              {convertMarkdown(heroBody)}
            </div>
          )}
          {hasCtas && (
            <div className={styles.calloutHero__ctas}>
              {ctas.map((cta: ComponentCta) => (
                <ComponentCtaButton
                  key={cta.ctaText}
                  ctaType='link'
                  ctaText={cta.ctaText}
                  ctaAria={cta.ctaAria}
                  ctaStyle={cta.ctaStyle}
                  ctaIcon={cta.ctaIcon}
                  ctaIconPosition={cta.ctaIconPosition}
                  ctaUrl={cta.ctaUrl}
                  ctaPage={cta.ctaPage}
                  ctaMarketoFormId={cta.ctaMarketoFormId}
                  ctaDigitalAsset={cta.ctaDigitalAsset}
                />
              ))}
            </div>
          )}
        </div>
        {hasMedia && (
          <div className={styles.calloutHero__image}>
            {isVideo && hasMedia && (
              <button
                type='button'
                className={`${styles.calloutHero__videoButton} ${
                  isPlaying ? styles.pause : styles.play
                }`}
                onClick={togglePlayPause}
              >
                {!isMobile && (isPlaying ? pauseVideoText : playVideoText)}
              </button>
            )}

            {isImage && <HeroImageClip heroMedia={heroMedia} ovalBottom />}
            {isVideo && renderVideoContent()}
          </div>
        )}
      </div>
      {gatedMediaMarketoFormId && (
        <Modal
          handleClose={() => handleFormClose()}
          isOpen={gatedModelOpen}
          triggerId={'calloutHero'}
        >
          <MarketoForm
            key='marketoEnquiry'
            formId={gatedMediaMarketoFormId}
            successMessage='Form submitted'
            onSuccess={handleFormSuccess}
          />
        </Modal>
      )}
    </div>
  );
};

export default ComponentCalloutHero;
